import React , { useEffect } from "react";
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import scrollTo from "gatsby-plugin-smoothscroll";
import Testimonail from "../components/testimonial";
//AOS
import AOS from "aos";
import "aos/dist/aos.css";
export default function Brand() {

  useEffect(() => {

    AOS.init({
      
    });
    
  }, []);

  useEffect(() => {

    if(AOS){
      AOS.refresh();
    }
    
  });

  return (
    <div>
      <Helmet>
        <title>
          Brand | OIC's eyewear collections from Luxury to economical brands
        </title>
        <meta httpEquiv="content-language" content="en-IN" />
        <meta
          name="title"
          content="Brand | OIC's eyewear collections from Luxury to economical brands"
        />
        <meta
          name="description"
          content="One-stop shopping from economical brands in a wide range of eyewear at OIC. Allow the brands to be profitable and to be at the pinnacle of its opponents."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.oicapps.com/" />
        <meta
          property="og:description"
          content="One-stop shopping from economical brands in a wide range of eyewear at OIC. Allow the brands to be profitable and to be at the pinnacle of its opponents."
        />
        <link
          rel="stylesheet"
          type="text/css"
          charset="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
      </Helmet>
      <Layout>
        <div
          className="bannerImage"
          style={{
            backgroundImage: `url("https://oicweb-media.s3.ap-south-1.amazonaws.com/brand-banner.jpg")`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6 bannerTextPadder">
                <h1>
                  LUXURY AND ECONOMICAL <span>BRANDS</span> UNDER ONE ROOF
                </h1>
                <a
                  onClick={() => {
                    scrollTo("#BuyNow");
                  }}
                  className="buttonStyleWhite hvr-forward"
                >
                  Buy Now
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="padding60 " id="BuyNow">
          <div className="container">
            <div className="row aligner" data-aos="fade-left" data-aos-duration="400">
              <div className="col-md-7 DesktopOnly">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/brand-01.jpg"
                  className="img-responsive"
                ></img>
              </div>
              <div className="col-md-5">
                <h2 className="realistic-marker-highlight">Rayban</h2>
                <ul>
                  <li>200+ Products</li>
                  <li>20% Discount Offer</li>
                  <li>1 Year Warranty</li>
                </ul>
                <a href="#" className="buttonStyle hvr-forward">
                  Buy Now
                </a>
                <br></br> <br></br>
              </div>
              <div className="col-md-7 mobile-only">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/brand-01.jpg"
                  className="img-responsive"
                ></img>
              </div>
            </div>
          </div>
        </div>
        <div className="padding60 blueBackground">
          <div className="container">
            <div className="row aligner" data-aos="fade-right" data-aos-duration="400">
              <div className="col-md-5">
                <h2 className="realistic-marker-highlight">Fastrack</h2>
                <ul>
                  <li>200+ Products</li>
                  <li>20% Discount Offer</li>
                  <li>1 Year Warranty</li>
                </ul>
                <a href="#" className="buttonStyle hvr-forward">
                  Buy Now
                </a>
                <br></br> <br></br>
              </div>
              <div className="col-md-7">
                <img
                  src="https://oicweb-media.s3.ap-south-1.amazonaws.com/brand-02.jpg"
                  className="img-responsive"
                ></img>
              </div>
            </div>
          </div>
        </div>
        <Testimonail />
      </Layout>
    </div>
  );
}
